@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.List::-webkit-scrollbar {
  display: none;
}

.List ::-moz-scrollbar {
  display: none;
}

.List{
  z-index: 0;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}

.root::-webkit-scrollbar {
  display: none;
}

.root ::-moz-scrollbar {
  display: none;
}

body{
  overscroll-behavior-y: contain;
}

#root{
  width: 100%;
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}


/* --------------------------- LIST --------------------------- */

.List{
  overflow: scroll;
  max-height: 100vh;
  padding-bottom: 80px;
}

.ListOption{

  display: flex;
  height: 90px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 12px;
  margin-right: 12px;
  background: #FFFFFF;
  box-shadow: 6px 12px 18px rgb(60 70 80 / 7%);
  border-radius: 8px;

}

.indicatorContainer{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

}

.indicatorContainer .indicator{
  width: 35px;
  height: 35px;
  background-color: #34206C;
  text-align: center;
  line-height: 34px;
  border-radius: 35px;
  color : white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  position: relative;


}

.ListOption .titleContainer{

  width: 100%;
  display: flex;
  align-items: center;
  
}

.indicator .title{

  position: absolute;
  top : -28px;
  left : 3px;
  color: #B0B0B0;

}

/* --------------------------- HEADER --------------------------- */

.boardName{

  color: white;
  margin-left: 10px;
  font-size: 24px;

}

.hamburguerMenu{

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

}

.filterHeader{

  background-color: #5235A5;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: height 500ms;

}

.filtericon{

  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.inconCounter{
  bottom: -6px;
  right: -9px;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #E56EA0;
  color: white;
  text-align: center;
  font-weight: 800;
  margin-left: 20px;
}

.searchBox{
  
  position: relative;
  display: flex;
  margin-left: 20px;
  
  transition: width 300ms;
  transition-timing-function : ease;
  
}

.searchInput{
  
  border: none;
  border-bottom: rgba(255,255,255,0.3) 1.5px solid;  

  height: 30px;
  background-color: transparent;
  outline: none;
  color: white;
  font-size: 20px;
  padding: 0px;
  transition: opacity 50ms;
  transition-timing-function : ease;
  padding-left: 30px;
 

}

.searchIcon{
 
  transition: opacity, scale 400ms;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  height: 100%;
  display: flex;
  align-items: center;
  
}

.searchwrapper{
  
  background-color: #3F297D;
  display: flex;
  align-items: center;
  width: 100%;
  opacity: 1;
  transition: opacity 200ms;

}

.filterContainer{

  position: absolute;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 50px);
  opacity: 1;
  align-content: flex-start;
  padding-left: 25px;
  padding-right: 25px;

}

.filterName{
  color: white;
  font-size: 22px;
  margin-left: 20px;
}
.boardSelector{

  display: flex;
  align-items: center;
  justify-self: flex-start;
  align-self: flex-start;

  background-color: #5235A5;
  width: 100%;
  opacity: 1;
  transition: opacity 200ms;
}

/* --------------------------- Input --------------------------- */

.inputSinleLineText{

  width: calc(100% - 10px);
  height: 100%;
  text-align: center;
  padding: 30px;
  font-size: 20px;
  font-family: "Roboto";
  border: none;
  opacity: 0;
  position: absolute;


}

.textInputValue{

  color: black;
  width: calc(100% - 50px);
  text-align: center;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 700;
  border-bottom: solid 2px rgba(0,0,0,0.1);
  padding-bottom: 20px;
  outline: none;


}

.textInputContaier{

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

/* --------------------------- FORM --------------------------- */



.formHeader{

  width: 100%;
  color: white;
  font-size: 30px;
  height: 70px;
  border-bottom: solid 0.5px rgba(255,255,255,1);
  line-height: 100px;
  display: flex;
  align-items: center;
  transition: opacity 500ms;

}

.backBtn{

  font-size: 0px !important;
  margin-right : 20px;
  width : 40px;
  height: 40px;
  border-radius: 40px;
  background: rgba(255,255,255,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 400ms;

}

.fromHeaderContent{

  opacity: 1;
  transition: opacity 400ms;

}

.formOption{

  width: 100%;
  
  /* background: rgba(255,255,255,0.02); */
  border-bottom: solid 0.5px rgba(255,255,255,0.6);
  align-items: center;
  color: white;
  font-size: 20px;
  padding-left: 15px;
  padding-right: 30px;
  justify-content: space-between;
  transition: opacity 300ms, transform 300ms ;
  
}

.formOption .title{
  display: flex;
  align-items: center;
  color: white;
  font-size: 20px;
  justify-content: space-between;
  width: 100%;
  margin-top: 23px;
  margin-bottom: 23px;
}

.formOption .selectedValues{

  margin-bottom: 20px;

}

.formOption .selectedValues .textWrapper{

  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 40px;
  background: rgba(255,255,255,0.3);
  width: fit-content;

}

.formOption .selectedValues .value{

  font-size: 16px;
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 35px;
  color: #34206C;
  text-align: center;
  line-height: 34px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 600;


}

.formOptionWrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.formOptionWrapper{
  
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 

}


.formWrapper{

  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  margin-left: 25px;
  margin-right: 25px;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: hidden;

}


/* --------------------------- Floating --------------------------- */

.flaotinBtn{

  position: absolute;
  bottom: 20px;
  right: 20px;
  
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: white;

  transform: scale(0);
  transition: transform 400ms;

  z-index: 3;

}

.flaotinBtn .contentWrapper{
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  justify-items: center;

}


/* --------------------------- PopUp --------------------------- */


.popUpWrapper{
  
  position: absolute;
  top: 0;
  left : 0; 
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 4;
  transition: opacity 200ms;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
}


.popUp{
  
  width: calc(100% - 100px);
  height: calc(100% - 150px);
  background-color: white;
  border-radius: 9px;
  
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  
  transition: height 200ms;

}

.popUp .top{

  height: 60px;
  width: 100%;
  background-color: #34206C;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  
  
}

.popUp .content{
  overflow: scroll;
  height: calc(100% - 120px);
  width: 100%;
  background-color: White;

}


.popUp .bottonBtn{
  
  background-color: rgba(0,0,0,0.1);
  height: 60px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-self: flex-end;

}

/* -------------------------------------------- */

.selectOptionContainer{

  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;


}/* --------------------------- LINE --------------------------- */

.lineHeader{

  display: flex;
  position: relative;
  
  padding: 15px;
  height: 100px;
  width: calc(100% - 30px);
  

  color: white;
  background: #34206C;
  
  align-items: center;
  font-size: 30px;
  
  align-items: center;
 

}

.lineWrapper{
  background-color: black;
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.viewsWrapper{
  
  background-color: black;
  height: calc(100% - 130px);
  display: flex;
  justify-content: center;
  align-items: center;

}

.board{

  background-color: black;
  width: 100%;

}

/* -----------------------------HOLDS----------------------- */

.holdsWrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.hold{
  
  position: absolute;
  z-index: 312;
  border-radius: 990px;
  opacity: 0.9;
  /* transition : transform 1000ms, border 1000ms, */
  
}


/* -----------------------------ADD LINE----------------------- */
.AddlineWrapper{
  
  background-color: #34206C;
  height: 100%;
  width: 100%;
  position: absolute;
  color: white;
  
}

/* -----------------------------sideMenu----------------------- */

.sideMenuContainer{

  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.editIconContainer{

  position: absolute;
  right: 20px;
  height: 60px;
  width: 60px;
  align-items: center;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;

}

.sideMenuContainer.open{
  display: block;
}

.sideMenuContainer.closed{
  display: none;
}

.sideMenu{
  height: 100%;
  width: 70%;
  background-color: #34206C;
  position: absolute;
  z-index: 1230490230;
  transition: transform 400ms;
  transform: translateX(0);

}
.sideMenu.open{
  transform: translateX(0);
}

.sideMenu.closed{
  transform: translateX(-100%);
}

.sideMenuBg{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
  opacity: 1;
  transition: opacity 400ms;
}

.sideMenuBg.open{
  opacity: 1;
}


.sideMenuBg.closed{
  opacity: 0;
}

.sideMenu .boardBtn{

  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  height: 60px;
  border-radius: 8px;
  background: white;
  padding-left: 15px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Roboto;
  color: #34206C;

}

.sideMenu .titleContainer{
  
  position: relative;
  height: 100px;

}

.sideMenu .title{
  
  position: absolute;
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  bottom: 0px;
  font-size: 40px;
  font-weight: bold;

}


.react-transform-wrapper {

    width: 100% !important;
    height: 100% !important;
  /* background-color: black !important;
  height: calc(100% - 130px) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; */

}

/* v------------------------------------------------ */

.viewsHeader{

  display: flex;
  position: relative;
  
  padding: 15px;
  height: 100px;
  width: calc(100% - 30px);
  

  color: white;
  background: #34206C;
  
  align-items: center;
  font-size: 30px;
  
  align-items: center;


}

.sliderCounter{

  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0px;
  height: 50px;
  background: white;

}

.react-transform-component{

  height: 100% !important;
  width: 100% !important;

}